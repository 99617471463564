<template>
  <v-container fluid>
    <v-widget title="Arquivos Contabilidade" class="pb-0" style="border-radius: 4px">
      <template v-slot:widget-content>
        <v-form v-model="valid" ref="formulario" lazy-validation>
          <v-container grid-list-md fluid class="pa-0" style="overflow: hidden auto; box-sizing: border-box">
            <v-row dense class="pt-1">
              <v-col cols="12" sm="3">
                <campo-data required placeholder=" " persistent-placeholder outlined hide-details autocomplete="off" role="presentation" label="Data Inicial" v-model="periodo.data_inicial"></campo-data>
              </v-col>
              <v-col cols="12" sm="3">
                <campo-data required placeholder=" " persistent-placeholder outlined hide-details autocomplete="off" role="presentation" label="Data Final" v-model="periodo.data_final"></campo-data>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field required placeholder=" " persistent-placeholder outlined hide-details autocomplete="off" role="presentation" label="E-mail" v-model="email"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-container fluid class="pa-0 pt-4 box shadow" style="text-align: end">
            <v-btn depressed class="mr-4" @click="cancelar">Cancelar</v-btn>
            <v-btn depressed color="primary" :loading="salvando" @click="enviarArquivos" :disabled="!podeEnviarEmail">Gerar Arquivos</v-btn>
          </v-container>
        </v-form>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    valid: false,
    validArquivos: false,
    loading: false,
    email: '',
  }),

  computed: {
    ...mapState('arquivo', {
      notas: 'notas',
      autorizadas: 'autorizadas',
      canceladas: 'canceladas',
      inutilizadas: 'inutilizadas',
      denegadas: 'denegadas',
      empresa: 'empresa',
      contador: 'contador',
      carregando: 'carregando',
      contadorDefinido: 'contadorDefinido',
      periodo: 'periodo',
    }),

    podeEnviarEmail() {
      const periodo = this.$store.state.arquivo.periodo;
      if (periodo.data_inicial && periodo.data_inicial != '' && periodo.data_final && periodo.data_final != '') {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    async enviarArquivos() {
      if (this.$refs.formArquivo.validate()) {
        try {
          await this.$store.dispatch('arquivo/enviarArquivos');
        } catch (error) {
          this.$store.commit('setMensagemErro', error);
        }
      }
    },

    cancelar() {
      this.$router.push('/home');
    },
  },
};
</script>
